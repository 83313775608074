<template>
    <loader
        :isLoading="isLoading"
        :error="error"
        errorMessage="federations.showError"
    >
        <h1 class="text-center mb-4">{{ $t("federations.update.title") }}</h1>
        <v-card outlined color="primary" class="mt-2">
            <v-card-text>
                <v-form ref="form" @submit.prevent="onUpdate">
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="federation.name"
                                :label="$t('federations.federation.name') + '*'"
                                :rules="requiredRule"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-text-field
                                type="number"
                                v-model="federation.siret"
                                :label="$t('federations.federation.siret')"
                                :rules="siretRule"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12" sm="6" class="py-0">
                            <v-text-field
                                v-model="federation.address.street"
                                :label="
                                    $t('federations.federation.address.street')
                                "
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="2" class="py-0">
                            <v-text-field
                                v-model="federation.address.post_code"
                                :label="
                                    $t(
                                        'federations.federation.address.postCode'
                                    )
                                "
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" class="py-0">
                            <v-text-field
                                v-model="federation.address.city"
                                :label="
                                    $t('federations.federation.address.city')
                                "
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-text-field
                        v-model="federation.phone"
                        :label="$t('federations.federation.phone')"
                        :rules="[phoneRule.phone(federation.phone)]"
                    ></v-text-field>
                    <v-text-field
                        v-model="federation.email"
                        :label="$t('federations.federation.email')"
                        :rules="[emailRule.email(federation.email)]"
                    ></v-text-field>
                    <br />
                    <v-select
                        v-model="federation.president"
                        :label="$t('federations.federation.president')"
                        :items="members"
                        item-value="id"
                    >
                        <template v-slot:selection="{ item }">
                            {{ item.lastname }} {{ item.firstname }}
                        </template>
                        <template v-slot:item="{ item }">
                            {{ item.lastname }} {{ item.firstname }}
                        </template>
                    </v-select>
                    <v-select
                        v-model="federation.director"
                        :label="$t('federations.federation.director')"
                        :items="members"
                        item-value="id"
                    >
                        <template v-slot:selection="{ item }">
                            {{ item.lastname }} {{ item.firstname }}
                        </template>
                        <template v-slot:item="{ item }">
                            {{ item.lastname }} {{ item.firstname }}
                        </template>
                    </v-select>
                    <v-select
                        v-model="federation.representative"
                        :label="$t('federations.federation.representative')"
                        :items="members"
                        item-value="id"
                    >
                        <template v-slot:selection="{ item }">
                            {{ item.lastname }} {{ item.firstname }}
                        </template>
                        <template v-slot:item="{ item }">
                            {{ item.lastname }} {{ item.firstname }}
                        </template>
                    </v-select>
                    <v-select
                        v-model="federation.contact"
                        :label="$t('federations.federation.contact')"
                        :items="members"
                        item-value="id"
                    >
                        <template v-slot:selection="{ item }">
                            {{ item.lastname }} {{ item.firstname }}
                        </template>
                        <template v-slot:item="{ item }">
                            {{ item.lastname }} {{ item.firstname }}
                        </template>
                    </v-select>
                    <v-select
                        v-model="federation.treasurer"
                        :label="$t('federations.federation.treasurer')"
                        :items="members"
                        item-value="id"
                    >
                        <template v-slot:selection="{ item }">
                            {{ item.lastname }} {{ item.firstname }}
                        </template>
                        <template v-slot:item="{ item }">
                            {{ item.lastname }} {{ item.firstname }}
                        </template>
                    </v-select>

                    <v-row class="mb-2" justify="center">
                        <v-col cols="auto">
                            <v-btn
                                color="primary"
                                type="submit"
                                :loading="isLoadingUpdate"
                            >
                                {{ $t("button.update") }}
                            </v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn
                                :to="{
                                    name: 'Federation',
                                    params: {
                                        federationId: federationId,
                                    },
                                }"
                            >
                                {{ $t("button.return") }}
                            </v-btn>
                        </v-col>
                    </v-row>
                    <p v-if="formIsInvalid" class="text-center warning--text">
                        {{ $t("form.error") }}
                    </p>
                    <p v-if="updateError" class="text-center error--text">
                        {{ $t("federations.update.error") }}
                    </p>
                </v-form>
            </v-card-text>
        </v-card>
    </loader>
</template>

<script>
import { mapState } from "vuex";
import Loader from "@/components/utils/Loader";

export default {
    components: {
        Loader,
    },

    props: {
        federationId: {
            type: [String, Number],
            required: true,
        },
    },

    data() {
        return {
            federation: {
                address: {},
            },
            members: [],

            isLoading: true,
            error: false,

            formIsInvalid: false,

            isLoadingUpdate: false,
            updateError: false,
        };
    },

    mounted() {
        this.getFederation();
    },

    computed: {
        ...mapState({
            requiredRule: (state) => state.utils.requiredRule,
            emailRule: (state) => state.utils.emailRule,
            phoneRule: (state) => state.utils.phoneRule,
            siretRule: (state) => state.utils.siretRule,
        }),
    },

    methods: {
        getFederation() {
            this.isLoading = true;
            this.error = false;

            this.$store
                .dispatch("ohme/getUpdateFederation", this.federationId)
                .then((data) => {
                    this.federation = data.data.federation;
                    if (this.federation.address == null) {
                        this.federation.address = {};
                    }

                    this.members = data.data.members;

                    if (this.federation.president) {
                        this.federation.president = this.federation.president.id;
                    }
                    if (this.federation.director) {
                        this.federation.director = this.federation.director.id;
                    }
                    if (this.federation.contact) {
                        this.federation.contact = this.federation.contact.id;
                    }
                    if (this.federation.representative) {
                        this.federation.representative = this.federation.representative.id;
                    }
                    if (this.federation.treasurer) {
                        this.federation.treasurer = this.federation.treasurer.id;
                    }

                    this.isLoading = false;
                })
                .catch((err) => {
                    if (
                        err !== undefined &&
                        err.response !== undefined &&
                        err.response.status == 403
                    ) {
                        this.$router.push({ name: "403" });
                    } else {
                        this.isLoading = false;
                        this.error = true;
                    }
                });
        },

        onUpdate() {
            if (this.$refs.form.validate()) {
                this.isLoadingUpdate = true;
                this.updateError = false;

                this.$store
                    .dispatch("ohme/updateFederation", {
                        federationId: this.federationId,
                        form: this.federation,
                    })
                    .then(() => {
                        this.$router.push({
                            name: "Federation",
                            params: {
                                federationId: this.federationId,
                            },
                        });
                    })
                    .catch(() => {
                        this.isLoadingUpdate = false;
                        this.updateError = true;
                    });
            } else {
                this.formIsInvalid = true;
            }
        },
    },
};
</script>
